import React from "react";
import modulePropOne from "../module-prop-1.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export default class BusinessMastery extends React.Component {
    render() {
        return (
            <div className={"wrapper-3 pa-t-30 m-pa-a-30 ma-h-a ma-b-30 m-ma-b-30"}>
                <div className={"pa-v-30 ma-b-30s tac"}>
                    <h4 className={"fw9 ma-b-30 m-ma-b-30"}>ARE YOU RUNNING YOUR BUSINESS<br/><span className={"pt"}>OR IS YOUR BUSINESS RUNNING YOU</span>?</h4>
                    <p className={"ma-b-20 m-ma-b-20"}>Having an idea for a business is one step, but knowing where to start, how to gain traction, and achieve real profitability is something else.</p>
                    <p className={"ma-b-30 m-ma-b-30"}>As a small business owner, you need to move out of working a job and move into being a true, full-time business owner. <span className={"pt"}>Business Mastery</span> is designed to reveal your unique entrepreneurial strengths, capitalize on your zone of genius, and bridge your knowledge gap using 6 powerful course modules and interactive coaching sessions. You will have direct access to me as well 8 to 10 other driven entrepreneurs over a 12-week period.</p>
                    <h4 className={"fw9"}>Are you ready to <span className={"pt"}>work less</span> and <span className={"pt"}>make more</span>?</h4>
                </div>
                <div className={"pa-v-30 m-pa-a-30 ma-b-30 m-ma-b-30"}>
                    <h5 className={"ma-b-30 m-ma-b-30 tac"}>Each module includes specific insights and real-world advice to get you to:</h5>
                    <div className={"df cf"}>
                        <div>
                            <img src={modulePropOne} height={"160"} className={"ma-r-30"} alt={"Module"} title={"Module"} />
                        </div>
                        <div>
                            <div><FontAwesomeIcon icon={faCheck} className={"fa-fw ma-r-10"} />Achieve an abundant cash flow.</div>
                            <div><FontAwesomeIcon icon={faCheck} className={"fa-fw ma-r-10"} />Master your business operations.</div>
                            <div><FontAwesomeIcon icon={faCheck} className={"fa-fw ma-r-10"} />Build your confidence to scale and outsource.</div>
                            <div><FontAwesomeIcon icon={faCheck} className={"fa-fw ma-r-10"} />Develop a consistent and effortless sales pipeline.</div>
                            <div><FontAwesomeIcon icon={faCheck} className={"fa-fw ma-r-10"} />Strategize your current and long-term business successes.</div>
                            <div><FontAwesomeIcon icon={faCheck} className={"fa-fw ma-r-10"} />Take advantage of all of the money-making opportunities available to you.</div>
                        </div>
                    </div>
                </div>
                <div
                    className={"df mf cf pa-v-30 m-pa-a-10 ma-b-30 wt tac br"}
                    style={{
                        backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-calculator.jpg")`,
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        height: 500
                    }}
                >
                    <div className={"csf"}>
                        <h4 className={"fw4 ma-b-30"} style={{lineHeight: "40px"}}>It’s not your fault that you haven’t been equipped<br/>with the skills, tools, and knowledge<br/>to run your small business profitably.</h4>
                        <h4 className={"fw4"}>But it <em className={"fw7"}>is</em> your fault if you continue to ignore it.</h4>
                    </div>
                </div>
                <div className={"pa-v-30 m-pa-a-30 ma-b-30 m-ma-b-30 tac"}>
                    <h4 className={"fw9 ma-b-30 m-ma-b-30"}>LEARNING IS <span className={"pt"}>VITAL</span> FOR SUCCESS.</h4>
                    <p className={"ma-b-20 m-ma-b-20"}>Often we are overwhelmed by the amount that we have to do in our everyday lives; taking care of our business, our family, ourselves... And running your own business means that you are adding even more onto that list, so how are you supposed to fit learning in too?</p>
                    <h5 className={"fw7 ma-b-20 m-ma-b-20"}>Well, what if I told you the <span className={"pt"}>more</span> you learn, the <span className={"pt"}>less</span> you have to work.</h5>
                    <p className={"ma-b-20 m-ma-b-20"}>Thinking strategically means making more while working less. It means learning to outsource, streamline, and charge more because you are worth more. It means learning to delegate while putting yourself in an informed position. It means planning for the future so you can live your best life now.</p>
                    <p>This is not the path of an employee or even an entrepreneur.</p>
                    <p className={"ma-b-30 m-ma-b-30"}>This is the path of a successful and fulfilled business owner.</p>
                    <a href={"https://sso.teachable.com/secure/128449/checkout/2009831/business-mastery"} className={"iblk pa-a-20 m-pa-a-20 br b wt violet"}>LEVEL UP. SIGN UP.</a>
                </div>
                <div className={"pa-v-30 m-pa-a-30 ma-b-30 m-ma-b-30 tac"}>
                    <h4 className={"fw9 ma-b-30 m-ma-b-30"}>COURSE CURRICULUM<span className={"pt"}>.</span></h4>
                    <h5 className={"fw7 ma-b-10 m-ma-b-10"}>Module 1: <span className={"fw9 pt"}>Positioning Your Business</span></h5>
                    <p className={"ma-b-30 m-ma-b-30"}>To build the foundation of a strong, marketable company, you’ll need to<br/>create a profitability plan (not just a business plan!).</p>
                    <h5 className={"fw7 ma-b-10 m-ma-b-10"}>Module 2: <span className={"fw9 pt"}>Business Engine Upgrade</span></h5>
                    <p className={"ma-b-30 m-ma-b-30"}>Save money by spending money! Learn how to think strategically, act strategically,<br/>and spend strategically to get ahead of the game.</p>
                    <h5 className={"fw7 ma-b-10 m-ma-b-10"}>Module 3: <span className={"fw9 pt"}>Forecasting the Future</span></h5>
                    <p className={"ma-b-30 m-ma-b-30"}>You don’t just want to make money, you want to make the most money possible! Expertly take<br/>on your competition by effectively identifying and replicating money-making activities.</p>
                    <h5 className={"fw7 ma-b-10 m-ma-b-10"}>Module 4: <span className={"fw9 pt"}>CEO Fastlane, Part 1</span></h5>
                    <p className={"ma-b-30 m-ma-b-30"}>Don't just cover your bases, know everything you can, plus more! I'll teach you how to make informed<br/>decisions that will not only grow your business today but long into the future.</p>
                    <h5 className={"fw7 ma-b-10 m-ma-b-10"}>Module 5: <span className={"fw9 pt"}>CEO Fastlane, Part 2</span></h5>
                    <p className={"ma-b-30 m-ma-b-30"}>Numbers don't have to be scary or stressful. Master your business’ finances so you end up<br/>making more money instead of paying heavily for unplanned expenses like back taxes.</p>
                    <h5 className={"fw7 ma-b-10 m-ma-b-10"}>Module 6: <span className={"fw9 pt"}>Grow Your Business</span></h5>
                    <p>Growing your business is an exciting prospect; it means people love what you have on offer! But it takes planning<br/>and foresight to get to this point which means you need to know how to fearlessly build and use credit.</p>
                </div>
                <div
                    className={"df mf sf pa-v-30 ma-b-30 wt br"}
                    style={{
                        backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-testimonial-one.jpg")`,
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        height: 500
                    }}
                >
                    <div className={"d-hf csf pa-h-30 m-pa-h-30"}>
                        <h4 className={"fw7 lh1 ma-b-20 m-ma-b-20"}>"I have especially been fortunate to have Michelle’s support in helping me in my business. You don’t have to struggle alone. Michelle is amazing and she is one of the most generous souls I think I have ever met and I am so grateful."</h4>
                        <h4 className={"fw9 lh1 tar"}>- Kathleen O.</h4>
                    </div>
                </div>
                <div className={"pa-v-30 m-pa-a-30 ma-b-30 m-ma-b-30 tac"}>
                    <h4 className={"fw9 ma-b-30 m-ma-b-30"}>BEING A SUCCESSFUL BUSINESS OWNER IS A <span className={"pt"}>MINDSET</span>.</h4>
                    <p>It requires skills that just aren’t always taught at a college or university. Things like having the desire and drive to succeed, setting goals that are realistic and achievable, and having the agility to endure uncertainty and overcome unforeseen obstacles will get you much further in the entrepreneurial world than a degree will. But that doesn’t mean you can ignore your shoebox full of invoices or skip filing taxes or be so busy that you don’t put aside the time to understand why you aren’t making more money.</p>'
                    <h4 className={"fw9 m-ma-b-20"}>You need a coach. You need a mentor. <span className={"pt"}>You need me</span>!</h4>
                </div>
                <div className={"df cw ma-b-30 m-ma-b-30 m-pa-h-30"}>
                    <div className={"pa-t-30"}>
                        <img
                            src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-instructor-one.jpg"}
                            className={"blk ma-r-30 m-ma-r-20"}
                            style={{float: "left", width: "40vw"}}
                            title={"Michelle Campbell"}
                            alt={"Michelle Campbell"}
                        />
                        <h4 className={"ma-b-30 m-ma-b-30"}>Hi, I am <span className={"pt"}>Michelle</span>, the creator of Business Mastery.</h4>
                        <p className={"ma-b-30 m-ma-b-30"}>I went from being a shy, single mother with no apparent entrepreneurial skills to a successful business owner, CPA, and certified business strategist, helping other small business owners' raise their bottom line and build the lives that they desire.</p>
                        <h5 className={"ma-b-30 m-ma-b-30"}>So, how did I get here?</h5>
                        <p>I often ask that myself, proudly! Going from not having any clue to getting multiple degrees and certifications to become a successful leader in an accounting and advisory firm was a wild ride. But one thing was a constant, and that was strategic thinking.</p>
                        <p className={"ma-b-30 m-ma-b-30"}>I certainly didn't know that at first, but as a single mother, I had to think strategically to get by. So when I started my own business, I applied this skill with my financial management knowledge and embraced change with a full and open heart, because exposure to the things we fear most often becomes the catalyst to our success.</p>
                        <p className={"ma-b-30 m-ma-b-30"}>I forced my self to be more (and be better at what I did) to create the change that put me on the right path to success. And while I now fear nothing, I am, and always will be, in a constant state of learning</p>
                        <p className={"ma-b-30 m-ma-b-30"}>So, join me on this wild and insanely rewarding journey as a small business owner, and walk away from this course armed with the secrets, fool-proof strategies, and the confidence to build and grow your very own successful business.</p>
                        <div className={"df mf cf"}>
                            <a href={"https://sso.teachable.com/secure/128449/checkout/2009831/business-mastery"} className={"csf iblk pa-a-20 m-pa-a-20 br b wt violet"}>NEXT COURSE DATES</a>
                        </div>
                    </div>
                </div>
                <div className={"pa-v-30 ma-b-30 tac"}>
                    <h4 className={"fw9 ma-b-30 m-ma-b-10"}>COURSE INCLUDES<span className={"pt"}>:</span></h4>
                    <div className={"df mf cf rw m-pa-a-20"}>
                        <div className={"qf ma-r-10 m-ma-r-10 m-ma-b-20"}>
                            <img
                                src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-icon-computer.jpg"}
                                className={"blk fw ma-b-5 m-ma-b-5"}
                                title={"Computer"}
                                alt={"Computer"}
                            />
                            <span>6 self-paced core learning modules</span>
                        </div>
                        <div className={"qf ma-r-10 m-ma-r-10 m-ma-b-20"}>
                            <img
                                src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-icon-notes.jpg"}
                                className={"blk fw ma-b-5 m-ma-b-5"}
                                title={"Notes"}
                                alt={"Notes"}
                            />
                            <span># powerful business planning strategies</span>
                        </div>
                        <div className={"qf ma-r-10 m-ma-r-10 m-ma-b-20"}>
                            <img
                                src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-icon-people.jpg"}
                                className={"blk fw ma-b-5 m-ma-b-5"}
                                title={"One on One"}
                                alt={"One on One"}
                            />
                            <span># targeted group coaching sessions</span>
                        </div>
                        <div className={"qf ma-r-10 m-ma-r-10"}>
                            <img
                                src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-icon-player.jpg"}
                                className={"blk fw ma-b-5 m-ma-b-5"}
                                title={"Video Player"}
                                alt={"Video Player"}
                            />
                            <span># highly engaging video lessons</span>
                        </div>
                        <div className={"qf ma-r-10 m-ma-r-10"}>
                            <img
                                src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-icon-phone.jpg"}
                                className={"blk fw ma-b-5 m-ma-b-5"}
                                title={"Phone"}
                                alt={"Phone"}
                            />
                            <span># one-on-one goal-driven support calls</span>
                        </div>
                        <div className={"qf"}>
                            <img
                                src={"https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-icon-like.jpg"}
                                className={"blk fw ma-b-5 m-ma-b-5"}
                                title={"Facebook"}
                                alt={"Facebook"}
                            />
                            <span>lifetime access to Facebook Group</span>
                        </div>
                    </div>
                </div>
                <div
                    className={"df ef pa-v-30 ma-b-30 wt br"}
                    style={{
                        backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/course/figure/business-mastery-testimonial-two.jpg")`,
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                    }}
                >
                    <div className={"hf csf pa-h-30 m-pa-h-30"}>
                        <h4 className={"fw7 lh1 ma-b-20 m-ma-b-20 m-pa-a-30"}>"I transitioned my financial affairs to Michelle after over 20 years with another CPA. As a new entrepreneur, it was important to have someone who understood small business matters well, was involved in the on-going fiduciary flow (not just at tax time) and who was patient in making sure that I develop my own financial savvy. Michelle is that and more. She’s clever, clear, concise, calm, courteous and a pleasure to work with."</h4>
                        <h4 className={"fw9 lh1 tar m-pa-b-30"}>- Angella F.</h4>
                    </div>
                </div>
                <div className={"pa-v-30 m-pa-a-30 ma-b-30 m-ma-b-30"}>
                    <h4 className={"fw9 ma-b-30 m-ma-b-30"}>FAQS<span className={"pt"}>:</span></h4>
                    <h5 className={"ma-b-10 m-ma-b-10"}><span className={"fw9"}>Q:</span> How much does the course cost?</h5>
                    <h5 className={"ma-b-30 m-ma-b-30 pt"}><span className={"fw9"}>A:</span> The total value of the course plus all the strategy guides, coaching sessions, and one-on-one support is valued at over $3250 but the next Business Mastery course is available to you for just $797.</h5>
                    <h5 className={"ma-b-10 m-ma-b-10"}><span className={"fw9"}>Q:</span> When does the course start?</h5>
                    <h5 className={"ma-b-30 m-ma-b-30 pt"}><span className={"fw9"}>A:</span> The next course starts August, 10th – so don’t miss out and register now!</h5>
                    <h5 className={"ma-b-10 m-ma-b-10"}><span className={"fw9"}>Q:</span> How long do I have access to the course content?</h5>
                    <h5 className={"ma-b-30 m-ma-b-30 pt"}><span className={"fw9"}>A:</span> You will have 1 year’s exclusive access to all of the Business Mastery content plus any additional content which will be regularly added.</h5>
                    <h5 className={"ma-b-10 m-ma-b-10"}><span className={"fw9"}>Q:</span> How many people will be in the group coaching sessions?</h5>
                    <h5 className={"ma-b-30 m-ma-b-30 pt"}><span className={"fw9"}>A:</span> The sessions will include 8-10 other like-minded entrepreneurs allowing you to share ideas while still receiving optimized learning and hands-on attention.</h5>
                    <h5 className={"ma-b-10 m-ma-b-10"}><span className={"fw9"}>Q:</span> Can I email questions to you during the course?</h5>
                    <h5 className={"pt"}><span className={"fw9"}>A:</span> Absolutely! You will have constant access to me during the course through multiple communication tools.</h5>
                </div>
                <div className={"pa-v-30 m-pa-v-30 ma-b-30 tac"}>
                    <h3 className={"fw9 ma-b-30 m-ma-b-30"}>If your business isn't profitable or<br/>if you want to make more money,<br/><span className={"pt"}>can you really afford to miss this course</span>?</h3>
                </div>
                <div className={"pa-b-30 m-pa-h-30 m-pa-b-30 ma-b-30 m-ma-b-30 tac"}>
                    <a href={"https://sso.teachable.com/secure/128449/checkout/2009831/business-mastery"} className={"iblk pa-a-20 m-pa-a-20 br b wt violet"}><h5 className={"fw9"}>REGISTER FOR BUSINESS MASTERY NOW!</h5></a>
                </div>
            </div>
        )
    }
}