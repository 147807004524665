import {faCircleNotch, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteComponentProps} from 'react-router';
import {withRouter} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {connect} from "react-dynadux";
import {API} from "../store/store";
import DOMPurify from "dompurify";
import React from "react";

interface Store {store: API;}
export interface RouterProps {id: string;name: string;}
export interface Article extends RouteComponentProps<RouterProps> {}

class ArticleController extends React.Component<Store & Article> {
    private readonly post_contact: (data: any) => void;
    private readonly reset_contact: () => void;

    constructor(props: any) {
        super(props);

        this.post_contact = this.props.store.form.actions.postContact.bind(this);
        this.reset_contact = this.props.store.form.actions.resetContact.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const name = this.props.match.params.name;

        this.props.store.article.actions.getArticles();
        this.props.store.article.actions.getArticle(id, name);

        this.reset_contact();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.location !== prevProps.location) {
            const id = this.props.match.params.id;
            const name = this.props.match.params.name;

            this.props.store.article.actions.getArticles();
            this.props.store.article.actions.getArticle(id, name);

            this.reset_contact();
        }
    }

    render() {
        const article = this.props.store.article.state.article;
        return (
            <div className={"wrapper ma-h-a"}>
                {
                    this.props.store.article.state.article !== undefined ?
                        <div className={"df sbf ma-b-20"}>
                            <div className={"tqf article-body m-pa-a-30 ma-r-30"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(article.body)}}>
                            </div>
                            <div className={"qf"}>
                                <div className={"g-t-bg br pa-a-20 m-pa-a-20"}>
                                    <Formik initialValues={() => {}} onSubmit={() => {}}>
                                        <Form className={"ma-b-20 m-ma-b-20"}>
                                            <div className={"df mf sbf"}>
                                                <Field type={"text"} className={"ff bb pa-v-5 pa-h-10 m-pa-v-5 m-pa-h-10"} value={""} placeholder={"Search for an article.."} />
                                                <button className={"b-bg wt pa-h-15 m-pa-h-15"}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        </Form>
                                    </Formik>
                                    <div className={"ma-b-20 m-ma-b-20"}>
                                        <h5 className={"ma-b-10 m-ma-b-10 lh2"}>How can we help you?</h5>
                                        <p>Strategic Thinking For Profit starts with a complimentary consultation to find out where you are & where you want to be. Call us at (919) 670-0186 to get things started.</p>
                                    </div>
                                    {this.props.store.form.state.loading ?
                                        this.props.store.form.state.loaded ?
                                            this.props.store.form.state.success ?
                                                <div className={"g-t-bg df mf cf br"} style={{minHeight: 332}}>
                                                    <div className={"csf bt"}>Thank you! We'll contact you shortly.</div>
                                                </div>
                                                :
                                                <></>
                                            :
                                            <div className={"g-t-bg df mf cf br"} style={{minHeight: 332}}>
                                                <FontAwesomeIcon icon={faCircleNotch} size={"3x"} className={"csf fa-fw fa-spin grt"} />
                                            </div>
                                        :
                                        <Formik initialValues={() => {}} onSubmit={value => {
                                            this.post_contact(value);
                                        }}>
                                            <Form name={"stfp_form"} className={"g-t-bg br pa-a-10 m-pa-a-10"} style={{minHeight: 415}}>
                                                {this.props.store.form.state.error ?
                                                    <div className={"ruby wt pa-v-5 pa-h-10 br ma-b-10"}>There was an error made. Please try again.</div>
                                                    :
                                                    <></>
                                                }
                                                <div className={"ma-b-10 m-ma-b-10"}><label><Field type={"text"} name={"stfp_form_name"} className={"fw bb pa-v-5 pa-h-10 m-pa-v-5 m-pa-h-10"} placeholder={"Your name"} required /></label></div>
                                                <div className={"ma-b-10 m-ma-b-10"}><label><Field type={"email"} name={"stfp_form_email"} className={"fw bb pa-v-5 pa-h-10 m-pa-v-5 m-pa-h-10"} placeholder={"Your email"} required /></label></div>
                                                <div className={"ma-b-10 m-ma-b-10"}><label><Field type={"text"} name={"stfp_form_phone"} className={"fw bb pa-v-5 pa-h-10 m-pa-v-5 m-pa-h-10"} placeholder={"Your phone"} required /></label></div>
                                                <div className={"ma-b-10 m-ma-b-10"}><label><Field component={"textarea"} name={"stfp_form_message"} className={"fw bb pa-v-5 pa-h-10 m-pa-v-5 m-pa-h-10"} placeholder={"What's your inquiry?"} required /></label></div>
                                                <div className={"iblk md-txt lh4 grt ma-b-15 m-ma-b-15"}>
                                                    <p>By submitting, I confirm this is a service inquiry and not an advertising message or solicitation. By clicking “Submit”, I acknowledge and agree to the creation of an account and to the Terms of Use & Privacy Policy.</p>
                                                </div>
                                                <button className={"fw bb pa-a-5 m-pa-a-5 b-bg wt cp"}>Submit</button>
                                            </Form>
                                        </Formik>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"tac pa-a-30 b ma-b-20"}>Article not found.</div>
                }
            </div>
        )
    };
}

export const Article = connect(
    withRouter(ArticleController),
    {
        shouldComponentUpdate: (action) => {
            return action.startsWith('A__') || action.startsWith('F__')
        }
    },
);