import React from 'react';
import {BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom';
import Header from './controller/sections/Header';
import {Footer} from './controller/sections/Footer';
import Player from "./controller/sections/Player";
import {SubscribeBox} from "./controller/sections/SubscribeBox";
import {Home} from './controller/Home';
import {Articles} from './controller/Articles';
import {Article} from './controller/Article';
import {Podcasts} from './controller/Podcasts';
import {Podcast} from './controller/Podcast';
import BusinessMastery from "./controller/BusinessMastery";
import Referral from './controller/Referral';
import About from './controller/About';
import Terms from './controller/Terms';
import Privacy from './controller/Privacy';
import Cookies from './controller/Cookies';
import DMCA from './controller/DMCA';
import Advertise from './controller/Advertise';
import {Contact} from './controller/Contact';
import {connect} from "react-dynadux";
import {API} from "./store/store";
import moment from "moment-timezone";
import './ScrewFrameworks.css';
import './Main.css';
import BusinessLoan from "./controller/tools/BusinessLoan";
import CreditUtilization from "./controller/tools/CreditUtilization";
import EmployerTax from "./controller/tools/EmployerTax";
import RevenueAllocation from "./controller/tools/RevenueAllocation";
import ChooseTool from "./controller/tools/ChooseTool";

export interface Store {store: API;}

class AppController extends React.Component<Store> {
    private readonly get_podcasts: () => void;
    private readonly get_articles: () => void;

    constructor(props: Store) {
        super(props);

        this.get_podcasts = this.props.store.podcast.actions.getPodcasts.bind(this);
        this.get_articles = this.props.store.article.actions.getArticles.bind(this);
    }

    componentDidMount() {
        this.get_podcasts();
        this.get_articles();
    }

    render() {
        // Podcast API
        const podcasts = this.props.store.podcast.state.podcasts;
        const podcast  = this.props.store.podcast.state.podcast;
        const podcast_cover_img = podcasts !== undefined && podcast.cover !== false ? podcast.cover : "default";
        const podcast_cover_url = `https://cdn.strategicthinkingforprofit.com/img/podcast/cover/${podcast_cover_img}.jpg`;

        // Article API
        const articles = this.props.store.article.state.articles;
        const article  = this.props.store.article.state.article;
        const article_cover_img = articles !== undefined && article.cover !== false ? article.cover : "default";
        const article_cover_url = `https://cdn.strategicthinkingforprofit.com/img/article/cover/${article_cover_img}.jpg`;

        return (
            <div className="App ma-h-a pa-b-30 ma-b-30">
                <Router>
                    <Switch>
                        <Route path="/blog/:id-:name">
                            <div
                                className={"cover b-bg ma-b-20"}
                                style={{backgroundImage: `url(${article_cover_url})`}}>
                                <div className={"b-t-bg"} style={{height: 400}}>
                                    <Header color={"white"} type={"article"}/>
                                    {article !== undefined ?
                                        <div className={"df mf cf"} style={{height: 250}}>
                                            <div className={"csf tac ma-t--30"}>
                                                <h4 className={"wt ma-b-20"}>{article.title}</h4>
                                                <h5 className={"wt fw4"}>{moment(article.created).tz("America/New_York").format("MMMM d, YYYY @ hA")}</h5>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <Article/>
                        </Route>
                        <Route path="/blog">
                            <div className={"cover b-bg ma-b-20"} style={{height: 600}}>
                                <Header color={"white"}/>
                            </div>
                            <Articles/>
                        </Route>
                        {/*<Route path="/podcasts">
                            <div className={"cover b-bg ma-b-20"} style={{height: 600}}>
                                <Header color={"white"}/>
                            </div>
                            <Podcasts/>
                        </Route>
                        <Route path="/podcast/:id-:name">
                            <div
                                className={"cover b-bg ma-b-20"}
                                style={{height: 600, backgroundImage: `url(${podcast_cover_url})`}}>
                                <Header color={"white"} type={"podcast"}/>
                                {podcast !== undefined ?
                                    <div className={"df mf cf"} style={{height: 420}}>
                                        <h4 className={"csf tac wt ma-t--30"}>{podcast.title}</h4>
                                    </div>
                                    :
                                    <div>
                                    </div>
                                }
                            </div>
                            <Podcast/>
                        </Route>*/}
                        <Route path="/courses/business-mastery">
                            <div
                                className={"cover b-bg"}
                                style={{
                                    backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/course/cover/business-mastery.jpg")`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "top center"
                                }}>
                                <div style={{height: 700, background: "rgba(20,20,30,.6)"}}>
                                    <Header color={"white"} type={"landing"}/>
                                    <div id={"lander"} className={"df mf sf wt wrapper ma-h-a"} style={{height: 520}}>
                                        <div className={"csf m-pa-h-30"}>
                                            <h1 className={"fw9 ma-b-10 m-ma-b-10"}>BUSINESS</h1>
                                            <h1 className={"fw9 ma-b-10 m-ma-b-10"}>MASTERY</h1>
                                            <h1 className={"fw9 ma-b-10 m-ma-b-10"}>COURSE<span className={"pt"}>.</span></h1>
                                            <h3 className={"fw4 ma-b-30 m-ma-b-30"}>Build your business and the life you desire!</h3>
                                            <a href={"https://sso.teachable.com/secure/128449/checkout/2009831/business-mastery"} className={"iblk violet wt pa-v-15 pa-h-25 m-pa-v-15 m-pa-h-25 br b"}><h5>Enroll Now</h5></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BusinessMastery/>
                        </Route>
                        <Route path="/referral">
                            <Header/>
                            <Referral/>
                        </Route>
                        <Route path="/about">
                            <Header/>
                            <About/>
                        </Route>
                        <Route path={"/tools"}>
                            <Header/>
                            <Switch>
                                <div className={"wrapper df sbf ma-h-a ma-b-30"}>
                                    <div className={"qf ma-r-20"}>
                                        <div className={"b-bg"}>
                                            <div className={"blk pa-v-10 pa-h-15 wt bb-bg b cd"}>Choose a Tool</div>
                                            <NavLink to={"/tools/business-loan"} className={"blk pa-v-10 pa-h-15 wt"} activeClassName={"violet"}>Business Loan</NavLink>
                                            <NavLink to={"/tools/credit-utilization"} className={"blk pa-v-10 pa-h-15 wt"} activeClassName={"violet"}>Credit Utilization</NavLink>
                                            <NavLink to={"/tools/employer-tax"} className={"blk pa-v-10 pa-h-15 wt"} activeClassName={"violet"}>Employer Tax</NavLink>
                                            <NavLink to={"/tools/revenue-allocation"} className={"blk pa-v-10 pa-h-15 wt"} activeClassName={"violet"}>Revenue Allocation</NavLink>
                                        </div>
                                    </div>
                                    <div className={"tqf"}>
                                        <Route path={"/tools/business-loan"} component={BusinessLoan} />
                                        <Route path={"/tools/credit-utilization"} component={CreditUtilization} />
                                        <Route path={"/tools/employer-tax"} component={EmployerTax} />
                                        <Route path={"/tools/revenue-allocation"} component={RevenueAllocation} />
                                        <Route path={"/tools"} exact component={ChooseTool} />
                                    </div>
                                </div>
                            </Switch>
                        </Route>
                        <Route path="/terms">
                            <Header/>
                            <Terms/>
                        </Route>
                        <Route path="/privacy">
                            <Header/>
                            <Privacy/>
                        </Route>
                        <Route path="/cookies">
                            <Header/>
                            <Cookies/>
                        </Route>
                        <Route path="/dmca">
                            <Header/>
                            <DMCA/>
                        </Route>
                        <Route path="/advertise">
                            <Header/>
                            <Advertise/>
                        </Route>
                        <Route path="/contact">
                            <Header/>
                            <Contact/>
                        </Route>
                        <Route path={"/"}>
                            <div
                                className={"cover b-bg"}
                                style={{
                                    backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/general/landing.jpg")`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "top center"
                                }}>
                                <div style={{height: "80vh"}}>
                                    <Header color={"white"} type={"landing"}/>
                                    <div id={"lander"} className={"df mf d-ef m-cf wt wrapper-2"} style={{height: "69vh"}}>
                                        <div className={"csf"}>
                                            <h1 className={"fw9 ma-b-10 m-ma-b-10"}>LEVEL UP<span className={"pt"}>.</span></h1>
                                            <h1 className={"fw9 ma-b-10 m-ma-b-10"}>MAKE MORE<span className={"pt"}>.</span></h1>
                                            <h1 className={"fw9"}>WORK LESS<span className={"pt"}>.</span></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Home/>
                        </Route>
                    </Switch>
                    <SubscribeBox />
                    <Footer/>
                    <Player/>
                </Router>
            </div>
        );
    }
}

export const App = connect(
    AppController,
    {
        shouldComponentUpdate: (action) => {
            return (action.startsWith('P__')) || (action.startsWith('A__'))
        },
    },
);