import {faCircleNotch, faEnvelope, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik, Form, Field} from "formik";
import {connect} from "react-dynadux";
import {API} from "../../store/store";
import Cookies from "js-cookie";
import React from "react";

interface Store {store: API;}

class SubscribeBoxSection extends React.Component<Store> {
    private readonly post_subscribe: (data: any) => void;
    state = {open: false}

    constructor(props: Store) {
        super(props);

        this.post_subscribe = this.props.store.form.actions.postSubscribe.bind(this);
    }

    openSubscription() {
        this.setState({open: true});
    }

    closeSubscription() {
        this.setState({open: false});
        Cookies.set('sub_popped', "true", {expires: 30});
    }

    componentDidMount() {
        let subPopped = Cookies.get('sub_popped');

        if (subPopped !== "true") setTimeout(() => {this.openSubscription()}, 7000);
    }

    render() {
        return (
            <div>
                <div className={"wrapper ma-h-a ma-h-a ma-b-20 m-ma-b-20"}>
                    <div
                        className={"df sbf pa-a-30 m-pa-a-30 b-bg br"}
                        style={{
                            backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/podcast/podcast-landing.jpg")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                    >
                        <div className={"hf"}>
                            <h4 className={"wt fw4 ma-b-10 m-ma-b-10"}>Need game changing tips?</h4>
                            <h4 className={"wt ma-b-10 m-ma-b-10"}>Strategize with us with frequent emails.</h4>
                            <h6 className={"wt fw4 lh3 m-ma-b-15"}>You'll learn from the best everyday on tips, tricks, everything you need to build your equity while shaving off time & expenses. Now is the time to take advantage of building your strength & knowledge. Articles, podcasts, updates on new courses, and more. See you soon!</h6>
                        </div>
                        <div className={"qf df cf csf"}>
                            <div className={"csf pa-h-30 pa-v-10 m-pa-h-30 m-pa-v-10 violet wt b cp"} onClick={() => this.openSubscription()}><FontAwesomeIcon icon={faEnvelope} className={"fa-fw ma-r-5 m-ma-r-5"} />Subscribe Now</div>
                        </div>
                    </div>
                </div>
                <div id={"subscribe-container"} className={`${this.state.open ? "" : "none"} df mf cf pf po-t-0 po-b-0 po-l-0 po-r-0 ma-a-a b-t-bg zi-ot`}>
                    <div id={"subscribe-box"} className={"pr df csf w-bg br"}>
                        <div
                            className={"hf bb-bg"}
                            style={{
                                backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/general/subscribe.png")`,
                                backgroundSize: "cover",
                                backgroundPosition: "top center"
                            }}
                        >
                        </div>
                        <div className={"df cf hf pa-a-30 m-pa-a-30"}>
                            {this.props.store.form.state.sub_loading ?
                                this.props.store.form.state.sub_loaded ?
                                    this.props.store.form.state.sub_success ?
                                        <div className={"df mf cf br"} style={{minHeight: 332}}>
                                            <div className={"csf bt"}>Thank you for subscribing!</div>
                                        </div>
                                        :
                                        <></>
                                    :
                                    <div className={"df mf cf br"} style={{minHeight: 332}}>
                                        <FontAwesomeIcon icon={faCircleNotch} size={"3x"} className={"csf fa-fw fa-spin pt"} />
                                    </div>
                                :
                                <Formik initialValues={() => {}} onSubmit={value => {
                                    this.post_subscribe(value);
                                }}>
                                    <Form className={"ff csf tac"}>
                                        {this.props.store.form.state.sub_error ?
                                            <div className={"ruby wt pa-v-5 pa-h-10 br ma-b-10"}>There was an error made. Please try again.</div>
                                            :
                                            <></>
                                        }
                                        <div className={"grt fw4 ma-b-15 m-ma-b-15"} style={{fontSize: 18}}>FINANCE LIKE A PRO</div>
                                        <h4 className={"fw4 ma-b-15 m-ma-b-15"}>SUBSCRIBE & <em className={"pt"}>STRATEGIZE</em></h4>
                                        <h6 className={"grt fw4 lh4 ma-b-30 m-ma-b-30"}>Get bonus tips and updates on how you can Level Up. Make More. Work Less. Build the life of your dreams!</h6>
                                        <div className={"ma-b-10 m-ma-b-10"}><label><Field type={"text"} name={"stfp_sub_name"} className={"blk fw b-bg wt br pa-v-5 pa-h-10 bb"} placeholder={"Your name"} /></label></div>
                                        <div className={"ma-b-10 m-ma-b-10"}><label><Field type={"email"} name={"stfp_sub_email"} className={"blk fw b-bg wt br pa-v-5 pa-h-10 bb"} placeholder={"Your email address"} /></label></div>
                                        <div><button className={"blk fw b-bg b wt br pa-v-5 pa-h-10 violet cp"}><FontAwesomeIcon icon={faPaperPlane} className={"fa-fw ma-r-5 m-ma-r-5"} />Sign Me Up!</button></div>
                                    </Form>
                                </Formik>
                            }
                        </div>
                        <div id={"close-button"} className={"md-txt pa po-t-0 po-r-5 pa-a-10 m-pa-a-10 cp"} onClick={() => this.closeSubscription()}>Close<FontAwesomeIcon icon={faTimes} className={"fa-fw"} size={"lg"} /></div>
                    </div>
                </div>
            </div>
        )
    }
}

export const SubscribeBox = connect(
    SubscribeBoxSection,
    {
        shouldComponentUpdate: (action) => action.startsWith('F__')
    },
);