import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";

export default class Referral extends React.Component {
    render() {
        return (
            <div className={"wrapper df sbf ma-h-a ma-b-30"}>
                <div className={"hf"}>
                    <h4 className={"fw2 ma-b-10"} style={{letterSpacing: 2}}>REFER A FRIEND</h4>
                    <p className={"ma-b-20"}>We're more than happy to help you with everything related to Strategic Thinking For Profit. Whether you need assistance or clarification on our podcasts, courses, tools, etc., we do our best to keep your mind at ease. If you're looking for other ways to reach us, check out our social media and contact information below! But before you use any of the contact methods, make sure to check out our <Link to={"/"} className={"pt"}>FAQs</Link> page.</p>
                </div>
                <div className={"tf"}>
                    <form name={"md_form"} className={"b-bg br pa-a-10"} noValidate>
                        <div className={"ma-b-10"}><label><input type={"text"} name={"md_form_ref_first_name"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Their first name.."} required /></label></div>
                        <div className={"ma-b-10"}><label><input type={"text"} name={"md_form_ref_last_name"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Their last name.."} required /></label></div>
                        <div className={"ma-b-10"}><label><input type={"email"} name={"md_form_ref_email"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Their email.."} required /></label></div>
                        <div className={"ma-b-30"}><label><input type={"text"} name={"md_form_ref_phone"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Their phone number.."} required /></label></div>
                        <div className={"ma-b-30"}><label><input type={"text"} name={"md_form_ref_about"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Anything we need to know about them?"} required /></label></div>
                        <div className={"ma-b-10"}><label><input type={"text"} name={"md_form_refr_full_name"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Your full name.."} required /></label></div>
                        <div className={"ma-b-10"}><label><input type={"email"} name={"md_form_refr_email"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Your email.."} required /></label></div>
                        <div className={"ma-b-10"}><label><input type={"text"} name={"md_form_refr_phone"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Your phone number.."} required /></label></div>
                        <div><button name={"md_form_submit"} className={"blk fw violet wt br cp pa-v-5"}><FontAwesomeIcon icon={faPaperPlane} className={"fa-fw ma-r-5 m-ma-r-5"}/>Send Referral</button></div>
                    </form>
                </div>
            </div>
        )
    }
}