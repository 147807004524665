import React from "react";
import {Link} from "react-router-dom";
import logoWhite from "../../logo-white.svg";
import logoBlack from "../../logo-black.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faEnvelope, faFileInvoice, faGraduationCap, faHome, faPodcast, faUsers} from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {
    color?: string;
    type?: string;
}

export default class Header extends React.Component<HeaderProps> {
    componentDidMount() {
        let nav = document.querySelector("header nav ul"),
            navToggle = document.querySelector("header nav .toggle");
        if (navToggle) {
            navToggle.addEventListener("click",
                function (e) {
                    if (nav) {
                        if (nav.classList.contains("open")) {
                            nav.classList.remove("open");
                        } else {
                            nav.classList.add("open");
                        }

                        e.preventDefault();
                    }
                }, false);
        }

        /* Any click outside of the nav element will close the menu if it's open */
        let specifiedElement = document.querySelector('header nav');

        document.addEventListener('click', function (event: MouseEvent) {
            let e: any = event.target;
            if (specifiedElement) {
                let isClickInside = specifiedElement.contains(e);
                if (!isClickInside && nav && nav.classList.contains("open")) {
                    nav.classList.remove("open");
                }
            }
        });
    }

    render() {
        const {color} = this.props;
        return (
            <header className={"df mf sbf pa-v-15 m-pa-a-20 ma-h-a ma-b-20"}>
                <Link to={"/"}>
                    <img src={color === "white" ?  logoWhite : logoBlack} height={"50"} alt={"Strategic Thinking For Profit"} title={"Strategic Thinking For Profit"} />
                </Link>
                <nav className={"mf ef csf pr"}>
                    <span className="dn toggle"><FontAwesomeIcon icon={faBars} size={"2x"} className={color === "white" ?  "wt" : "bt"}/></span>
                    <ul className={"df"}>
                        <li className={"ma-r-30"}><Link to={"/"} className={"bt"}><FontAwesomeIcon icon={faHome} className={"fa-fw ma-r-5 m-ma-r-5"}/>Home</Link></li>
                        <li className={"ma-r-30"}><Link to={"/courses/business-mastery"} className={"bt"}><FontAwesomeIcon icon={faGraduationCap} className={"fa-fw ma-r-5 m-ma-r-5"}/>Courses</Link></li>
                        <li className={"ma-r-30"}><Link to={"/blog"} className={"bt"}><FontAwesomeIcon icon={faFileInvoice} className={"fa-fw ma-r-5 m-ma-r-5"}/>Information Center</Link></li>
                        {/*<li className={"ma-r-30"}><Link to={"/podcasts"} className={"bt"}><FontAwesomeIcon icon={faPodcast} className={"fa-fw ma-r-5 m-ma-r-5"}/>Podcasts</Link></li>*/}
                        <li className={"ma-r-30"}><Link to={"/about"} className={"bt"}><FontAwesomeIcon icon={faUsers} className={"fa-fw ma-r-5 m-ma-r-5"}/>About Us</Link></li>
                        <li><Link to={"/contact"} className={"bt"}><FontAwesomeIcon icon={faEnvelope} className={"fa-fw ma-r-5 m-ma-r-5"}/>Contact Us</Link></li>
                    </ul>
                </nav>
            </header>
        )
    };
}