import React from "react";

export default class EmployerTax extends React.Component {
    render() {
        return (
            <div className={"df"}>
                <div className={"hf pa-a-20 bb ma-r-20 b-bg br"}>
                    <form className={"blk"}>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>State<span className={"ma-l-5 rt"}>*</span></div>
                            <select className={"blk fw bb pa-v-10 pa-h-15 br"}>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Wage<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>SUI<span className={"ma-l-5 rt"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter between 0 to 100"} />
                        </label>
                        <button className={"blk fw bb pa-v-10 b sapphire wt br"}>Calculate</button>
                    </form>
                </div>
                <div className={"hf pa-a-20 bb sapphire wt br"}>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Social Security Tax</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Medicare Tax</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>FUTA</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div>
                        <h5 className={"ma-b-20 fw4"}>Unemployment Tax</h5>
                        <h4>$0.00</h4>
                    </div>
                </div>
            </div>
        )
    }
}