import * as serviceWorker from './serviceWorker';
import {Provider} from "react-dynadux";
import {Store} from "./store/store";
import ReactDOM from 'react-dom';
import React from 'react';
import {App} from './App';

const store = Store();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();