import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStepBackward, faStepForward, faPlay, faRandom, faLink, faVolumeDown} from "@fortawesome/free-solid-svg-icons";

export default class Player extends React.Component {
    render() {
        return (
            <div className={"pf fw bb po-b-0 pa-a-10 bb-bg grt"}>
                <div className={"wrapper ma-h-a df mf sbf"}>
                    <div className={"controls qf csf df sbf"}>
                        <FontAwesomeIcon icon={faStepBackward} className={"cp"}/>
                        <FontAwesomeIcon icon={faPlay} className={"cp"}/>
                        <FontAwesomeIcon icon={faStepForward} className={"cp"}/>
                        <FontAwesomeIcon icon={faRandom} className={"cp"}/>
                        <FontAwesomeIcon icon={faLink} className={"cp"}/>
                    </div>
                    <div className={"progress ff csf tac"}>0:00-0:00</div>
                    <div className={"volume qf csf tac"}><FontAwesomeIcon icon={faVolumeDown} size={"lg"} className={"cp"}/></div>
                    <div className={"info qf csf tar"}>Song</div>
                </div>
            </div>
        )
    };
}