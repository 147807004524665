import {podcast} from "./reducers/podcast";
import {article} from "./reducers/article";
import {form} from "./reducers/form";
import {createStore} from "dynadux";

export interface API extends ReturnType<typeof Store> {}

export const Store = () => {
    const store = createStore({
        //onChange: (state, action, payload) => {}
    });

    return {
        podcast: podcast(store),
        article: article(store),
        form: form(store),
        provider: store.provider
    };
};