import {faCircleNotch, faEnvelopeOpenText, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik, Form, Field} from "formik";
import {Link} from "react-router-dom";
import {API} from "../store/store";
import React from "react";
import {connect} from "react-dynadux";

interface Store {store: API;}

class ContactController extends React.Component<Store> {
    private readonly post_contact: (data: any) => void;
    private readonly reset_contact: () => void;

    constructor(props: Store) {
        super(props);

        this.post_contact = this.props.store.form.actions.postContact.bind(this);
        this.reset_contact = this.props.store.form.actions.resetContact.bind(this);
    }

    componentDidMount() {
        this.reset_contact();
    }

    render() {
        return(
            <div className={"wrapper df sbf ma-h-a ma-b-30"}>
                <div className={"hf"}>
                    <h4 className={"fw2 ma-b-10"} style={{letterSpacing: 2}}>CONTACT US</h4>
                    <p className={"ma-b-20"}>We're more than happy to help you with everything related to Strategic Thinking For Profit. Whether you need assistance or clarification on our podcasts, courses, tools, etc., we do our best to keep your mind at ease. If you're looking for other ways to reach us, check out our social media and contact information below! But before you use any of the contact methods, make sure to check out our <Link to={"/"} className={"pt"}>FAQs</Link> page.</p>
                    <h5 className={"fw2 ma-b-10"}>OTHER METHODS</h5>
                    <p><FontAwesomeIcon icon={faEnvelopeOpenText} className={"grt fa-fw ma-r-5 m-ma-r-5"}/><a href={"mailto:info@strategicthinkingforprofit.com"} className={"pt"}>info@strategicthinkingforprofit.com</a></p>
                    <p><FontAwesomeIcon icon={faFacebookSquare} className={"grt fa-fw ma-r-5 m-ma-r-5"}/><a href={"https://facebook.com/strategicthinkingforprofit"} className={"pt"} target={"_blank"} rel={"noopener noreferrer"}>Message us on Facebook</a></p>
                    <p><FontAwesomeIcon icon={faTwitter} className={"grt fa-fw ma-r-5 m-ma-r-5"}/><a href={"https://twitter.com/higherstrategy"} className={"pt"} target={"_blank"} rel={"noopener noreferrer"}>Send us a DM on Twitter</a></p>
                </div>
                <div className={"tf"}>
                    {this.props.store.form.state.loading ?
                        this.props.store.form.state.loaded ?
                            this.props.store.form.state.success ?
                                <div className={"b-bg df mf cf br"} style={{minHeight: 332}}>
                                    <div className={"csf wt"}>Thank you! We'll contact you shortly.</div>
                                </div>
                                :
                                <></>
                            :
                            <div className={"b-bg df mf cf br"} style={{minHeight: 332}}>
                                <FontAwesomeIcon icon={faCircleNotch} size={"3x"} className={"csf fa-fw fa-spin pt"} />
                            </div>
                        :
                        <Formik initialValues={() => {}} onSubmit={value => {
                            this.post_contact(value);
                        }}>
                            <Form name={"stfp_form"} className={"b-bg br pa-a-10"} style={{minHeight: 312}}>
                                {this.props.store.form.state.error ?
                                    <div className={"ruby wt pa-v-5 pa-h-10 br ma-b-10"}>There was an error made. Please try again.</div>
                                    :
                                    <></>
                                }
                                <div className={"ma-b-10"}><label><Field type={"text"} name={"stfp_form_name"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Enter your full name.."} required /></label></div>
                                <div className={"ma-b-10"}><label><Field type={"email"} name={"stfp_form_email"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Enter your email.."} required /></label></div>
                                <div className={"ma-b-10"}><label><Field type={"text"} name={"stfp_form_phone"} className={"fw bb br blk pa-v-5 pa-h-10"} placeholder={"Enter your phone.."} required /></label></div>
                                <div className={"ma-b-10"}><label><Field component={"textarea"} name={"stfp_form_message"} className={"fw bb blk br pa-v-5 pa-h-10"} placeholder={"What's your inquiry?"} required /></label></div>
                                <div><button name={"stfp_form_submit"} className={"blk fw violet wt br cp pa-v-5"}><FontAwesomeIcon icon={faPaperPlane} className={"fa-fw ma-r-5 m-ma-r-5"}/>Send Message</button></div>
                            </Form>
                        </Formik>
                    }
                </div>
            </div>
        )
    };
}

export const Contact = connect(
    ContactController,
    {
        shouldComponentUpdate: (action) => action.startsWith('F__')
    },
);