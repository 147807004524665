import ArticleItem from "./sections/ArticleItem";
import {connect} from "react-dynadux";
import {API} from "../store/store";
import React from "react";

interface StoreProp {store: API;}

class ArticlesController extends React.Component<StoreProp> {
    render() {
        return(
            <div className={"wrapper-2 ma-h-a m-pa-a-20"}>
                <div className={"df rw"}>
                    {
                        this.props.store.article.state.articles.map((item: any) => (
                            <ArticleItem data={item} padding={"pa-r-10"} key={item.id} />
                        ))
                    }
                </div>
            </div>
        )
    };
}

export const Articles = connect(
    ArticlesController,
    {
        shouldComponentUpdate: (action) => action.startsWith('A__')
    },
);