import React from "react";

export default class CreditUtilization extends React.Component {
    render() {
        return (
            <div className={"df"}>
                <div className={"hf pa-a-20 bb ma-r-20 b-bg br"}>
                    <form>
                        <div className={"df"}>
                            <label className={"hf ma-r-20 ma-b-20"}>
                                <div className={"wt b ma-b-10"}>Card 1 Balance<span className={"rt ma-l-5"}>*</span></div>
                                <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                            </label>
                            <label className={"hf ma-b-20"}>
                                <div className={"wt b ma-b-10"}>Card 1 Limit<span className={"ma-l-5 rt"}>*</span></div>
                                <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                            </label>
                        </div>
                        <div className={"df"}>
                            <label className={"hf ma-r-20 ma-b-20"}>
                                <div className={"wt b ma-b-10"}>Card 2 Balance</div>
                                <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                            </label>
                            <label className={"hf ma-b-20"}>
                                <div className={"wt b ma-b-10"}>Card 2 Limit</div>
                                <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                            </label>
                        </div>
                        <div className={"df"}>
                            <label className={"hf ma-r-20 ma-b-20"}>
                                <div className={"wt b ma-b-10"}>Card 3 Balance</div>
                                <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                            </label>
                            <label className={"hf ma-b-20"}>
                                <div className={"wt b ma-b-10"}>Card 3 Limit</div>
                                <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                            </label>
                        </div>
                        <button className={"blk fw bb pa-v-10 b sapphire wt br"}>Calculate</button>
                    </form>
                </div>
                <div className={"hf pa-a-20 bb sapphire wt br"}>
                    <div className={"df"}>
                        <div className={"tqf"}>
                            <h5 className={"ma-b-20 fw4"}>Overall Utilization</h5>
                            <h1><span>0</span>%</h1>
                        </div>
                        <div className={"hf"}>
                            <div className={"ma-b-30"}>
                                <h5 className={"ma-b-10 fw4"}>Card One</h5>
                                <h3><span>0</span>%</h3>
                            </div>
                            <div className={"ma-b-30"}>
                                <h5 className={"ma-b-10 fw4"}>Card Two</h5>
                                <h3><span>0</span>%</h3>
                            </div>
                            <div>
                                <h5 className={"ma-b-10 fw4"}>Card Three</h5>
                                <h3><span>0</span>%</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}