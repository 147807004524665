import {ICreateStoreAPI} from "dynadux";
import axios from "axios";

interface State {
    articles: any;
    article: any;
}

enum Actions {
    GET_ARTICLES = "A__GET_ARTICLES",
    LOAD_ARTICLES = "A__LOAD_ARTICLES",
    GET_ARTICLE = "A__GET_ARTICLE",
    LOAD_ARTICLE = "A__LOAD_ARTICLE",
}

interface Article {
    id: string;
    slug: string;
}

interface Data {
    data: Array<JSON>;
}

export const article = (store: ICreateStoreAPI) => {
    const section = store.createSection<State>({
        section: "article",
        initialState: {articles: [], article: []},
        reducers: {
            [Actions.GET_ARTICLES]: ({dispatch}) => {
                axios.get("https://api.strategicthinkingforprofit.com/articles")
                    .then(r => {
                        dispatch(Actions.LOAD_ARTICLES, {data: r.data});
                    });
            },
            [Actions.LOAD_ARTICLES]: ({payload}) => {
                const {data}: Data = payload;
                return {articles: data};
            },
            [Actions.GET_ARTICLE]: ({payload, dispatch}) => {
                const {id, slug}: Article = payload;
                axios.get(`https://api.strategicthinkingforprofit.com/article/${id}-${slug}`)
                    .then(r => {
                        dispatch(Actions.LOAD_ARTICLE, {data: r.data});
                    });
            },
            [Actions.LOAD_ARTICLE]: ({payload}) => {
                const {data}: Data = payload;
                return {article: data};
            }
        }
    });

    return {
        get state(): State {
            return section.state;
        },
        actions: {
            getArticles: (): void => section.dispatch<void>(Actions.GET_ARTICLES),
            getArticle: (id: string, slug: string): void => section.dispatch<Article>(Actions.GET_ARTICLE, {id, slug}),
        }
    }
};