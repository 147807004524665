import {faAd, faBalanceScale, faCoins, faCookie, faCreditCard, faEnvelope, faEye, faFileInvoiceDollar, faGavel, faMoneyCheckAlt} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faArrowAltCircleUp, faCopyright} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import React from "react";
import {API} from "../../store/store";
import {connect} from "react-dynadux";

interface StoreProp {store: API;}

class FooterSection extends React.Component<StoreProp> {
    render() {
        return(
            <footer className={"ma-h-a"}>
                <div className={"df sbf pa-a-10 m-pa-h-20 g-t-bg"}>
                    <div className={"tf wt violet br ma-r-15 m-pa-a-20"}>
                        <div className={"w-t-bg wt pa-v-15 pa-h-20"}>
                            <p className={"ma-b-10 m-ma-b-10"}><strong>The mission</strong> of Strategic Thinking for Profit is to provide entrepreneurs who struggle with limited information and mentoring, access to valuable resources, products, and services that will elevate business growth and increase personal fulfillment.</p>
                            <p>Our vision is to inspire, educate, and become a well-known brand respected for honesty, integrity, and customer commitment.</p>
                        </div>
                    </div>
                    <nav className={"tqf df sbf pa-l-10"}>
                        <ul className={"hf ma-r-25 m-pa-a-30 m-tac"}>
                            <li><span className={"iblk ma-b-5 m-ma-b-10 grt"} style={{letterSpacing: 2}}>LEARNING CENTER</span></li>
                            {this.props.store.article.state.articles.slice(0,5).map((item: any, i: number) => (
                                <li className={"lh3 m-ma-h-10 m-ma-b-10"} key={i}><Link to={`/article/${item["id"]}-${item["slug"]}`} className={"iblk bt ma-b-5"}>{item["title"]}</Link></li>
                            ))}
                        </ul>
                        <ul className={"qf ma-r-25 m-ma-b-20 m-tac"}>
                            <li><span className={"iblk ma-b-5 m-ma-b-5 grt"} style={{letterSpacing: 2}}>COMPANY</span></li>
                            <li><Link to={"/terms"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faGavel} className={"fa-fw ma-r-5 m-ma-r-5"}/>Terms of Use</Link></li>
                            <li><Link to={"/privacy"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faEye} className={"fa-fw ma-r-5 m-ma-r-5"}/>Privacy Policy</Link></li>
                            <li><Link to={"/cookies"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faCookie} className={"fa-fw ma-r-5 m-ma-r-5"}/>Cookie Policy</Link></li>
                            <li><Link to={"/dmca"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faBalanceScale} className={"fa-fw ma-r-5 m-ma-r-5"}/>DMCA Policy</Link></li>
                            <li><Link to={"/advertise"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faAd} className={"fa-fw ma-r-5 m-ma-r-5"}/>Advertise</Link></li>
                            <li><Link to={"/contact"} className={"bt"}><FontAwesomeIcon icon={faEnvelope} className={"fa-fw ma-r-5 m-ma-r-5"}/>Contact Us</Link></li>
                        </ul>
                        <ul className={"qf ma-r-25 m-ma-b-20 m-tac"}>
                            <li><span className={"iblk ma-b-5 m-ma-b-5 grt"} style={{letterSpacing: 2}}>OUR TOOLS</span></li>
                            <li><Link to={"/tools/business-loan"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faMoneyCheckAlt} className={"fa-fw ma-r-5 m-ma-r-5"}/>Business Loan</Link></li>
                            <li><Link to={"/tools/credit-utilization"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faCreditCard} className={"fa-fw ma-r-5 m-ma-r-5"}/>Credit Utilization</Link></li>
                            <li><Link to={"/tools/employer-tax"} className={"iblk bt ma-b-5 m-ma-b-5"}><FontAwesomeIcon icon={faFileInvoiceDollar} className={"fa-fw ma-r-5 m-ma-r-5"}/>Employer Tax</Link></li>
                            <li><Link to={"/tools/revenue-allocation"} className={"iblk bt ma-b-5"}><FontAwesomeIcon icon={faCoins} className={"fa-fw ma-r-5 m-ma-r-5"}/>Revenue Allocation</Link></li>
                        </ul>
                        <ul className={"qf mf cf m-pa-v-30"}>
                            <li className={"mn"}><span className={"iblk ma-b-5 grt"} style={{letterSpacing: 2}}>FOLLOW US</span></li>
                            <li className={"m-ma-r-20"}><a href={"https://facebook.com/strategicthinkingforprofit"} className={"iblk bt ma-b-5"}><FontAwesomeIcon icon={faFacebookSquare} className={"grt fa-fw ma-r-5 m-ma-r-5 m-2x"}/><span className={"mn"}>Facebook</span></a></li>
                            <li className={"m-ma-r-20"}><a href={"https://instagram.com/strategicthinkingforprofit"} className={"iblk bt ma-b-5"}><FontAwesomeIcon icon={faInstagram} className={"grt fa-fw ma-r-5 m-ma-r-5 m-2x"}/><span className={"mn"}>Instagram</span></a></li>
                            <li className={"m-ma-r-20"}><a href={"https://twitter.com/higherstrategy"} className={"iblk bt ma-b-5"}><FontAwesomeIcon icon={faTwitter} className={"grt fa-fw ma-r-5 m-ma-r-5 m-2x"}/><span className={"mn"}>Twitter</span></a></li>
                            <li><a href={"https://youtube.com/channel/UCTwD3k6t7BxPJp1wTXvGKkQ"} className={"iblk bt ma-b-5"}><FontAwesomeIcon icon={faYoutube} className={"grt fa-fw ma-r-5 m-ma-r-5 m-2x"}/><span className={"mn"}>YouTube</span></a></li>
                        </ul>
                    </nav>
                </div>
                <div className={"df m-tac sbf m-pa-a-20 pa-v-10 pa-h-10 b-bg wt"}>
                    <div><FontAwesomeIcon icon={faCopyright} className={"fa-fw ma-r-5 m-ma-r-5"}/>2020 Strategic Thinking For Profit. All Rights Reserved.</div>
                    <Link to={"/#App"} className={"wt"}><FontAwesomeIcon icon={faArrowAltCircleUp} className={"fa-fw ma-r-5 m-ma-r-5"}/>Back to the top</Link>
                </div>
            </footer>
        )
    };
}

export const Footer = connect(
    FooterSection,
    {
        shouldComponentUpdate: (action) => action.startsWith('A__')
    },
);