import React from "react";

export default class RevenueAllocation extends React.Component {
    render() {
        return (
            <div className={"df"}>
                <div className={"hf pa-a-20 bb ma-r-20 b-bg br"}>
                    <form className={"blk"}>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Revenue<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Owner's Daw<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Payroll<span className={"ma-l-5 rt"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter between 0 to 100"} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Cost of Goods<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Other Expenses<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Taxes<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <button className={"blk fw bb pa-v-10 b sapphire wt br"}>Calculate</button>
                    </form>
                </div>
                <div className={"hf pa-a-20 bb sapphire wt br"}>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Owner's Draw</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Payroll</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Cost of Goods</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Other Expenses</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Taxes</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div>
                        <h5 className={"ma-b-20 fw4"}>Total</h5>
                        <h3>$0.00</h3>
                    </div>
                </div>
            </div>
        )
    }
}