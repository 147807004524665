import {ICreateStoreAPI} from "dynadux";
import axios from "axios";

interface State {
    sub_loading: boolean;
    sub_loaded: boolean;
    sub_success: boolean;
    sub_error: boolean;

    loading: boolean;
    loaded: boolean;
    success: boolean;
    error: boolean;
}

enum Actions {
    POST_CONTACT = 'F__POST_CONTACT',
    UPDATE_CONTACT = 'F__UPDATE_CONTACT',
    RESET_CONTACT = 'F__RESET_CONTACT',
    POST_SUB = 'F__POST_SUB',
    UPDATE_SUB = 'F__UPDATE_SUB',
}

interface Form {
    data: any
}

interface Status {
    loading: boolean;
    loaded: boolean;
    success: boolean;
    error: boolean;
}

interface Subscribed {
    sub_loading: boolean;
    sub_loaded: boolean;
    sub_success: boolean;
    sub_error: boolean;
}

export const form = (store: ICreateStoreAPI) => {
    const section = store.createSection<State>({
        section: "form",
        initialState: {sub_loading: false, sub_loaded: false, sub_success: false, sub_error: false, loading: false, loaded: false, success: false, error: false},
        reducers: {
            [Actions.POST_CONTACT]: ({payload, dispatch}) => {
                const {data}: Form = payload;

                dispatch(Actions.UPDATE_CONTACT, {loading: true, loaded: false, success: false, error: false})

                axios.post("https://api.strategicthinkingforprofit.com/contact", data)
                    .then(r => {
                        if (r.data.success === true) {
                            dispatch(Actions.UPDATE_CONTACT, {loading: true, loaded: true, success: true, error: false});
                        } else {
                            dispatch(Actions.UPDATE_CONTACT, {loading: false, loaded: false, success: false, error: true});
                        }
                    })
                    .catch(() => {
                        dispatch(Actions.UPDATE_CONTACT, {loading: false, loaded: false, success: false, error: true});
                    });
            },
            [Actions.UPDATE_CONTACT]: ({payload}) => {
                const {loading, loaded, success, error}: Status = payload;
                return {loading, loaded, success, error};
            },
            [Actions.RESET_CONTACT]: () => {
                return {loading: false, loaded: false, success: false, error: false}
            },
            [Actions.POST_SUB]: ({payload, dispatch}) => {
                const {data}: Form = payload;

                dispatch(Actions.UPDATE_SUB, {sub_loading: true, sub_loaded: false, sub_success: false, sub_error: false})

                axios.post("https://api.strategicthinkingforprofit.com/subscribe", data)
                    .then(r => {
                        if (r.data.success === true) {
                            dispatch(Actions.UPDATE_SUB, {sub_loading: true, sub_loaded: true, sub_success: true, sub_error: false});
                        } else {
                            dispatch(Actions.UPDATE_SUB, {sub_loading: false, sub_loaded: false, sub_success: false, sub_error: true});
                        }
                    })
                    .catch(() => {
                        dispatch(Actions.UPDATE_SUB, {sub_loading: false, sub_loaded: false, sub_success: false, sub_error: true});
                    });
            },
            [Actions.UPDATE_SUB]: ({payload}) => {
                const {sub_loading, sub_loaded, sub_success, sub_error}: Subscribed = payload;
                return {sub_loading, sub_loaded, sub_success, sub_error};
            },
        }
    });

    return {
        get state(): State {
            return section.state;
        },
        actions: {
            postContact: (data: any): void => section.dispatch<Form>(Actions.POST_CONTACT, {data}),
            resetContact: (): void => section.dispatch<void>(Actions.RESET_CONTACT),
            postSubscribe: (data: any): void => section.dispatch<Form>(Actions.POST_SUB, {data}),
        }
    }
}