import React from "react";
import {Link} from "react-router-dom";

interface ArticleProps {
    data: any;
    padding: string;
}

export default class ArticleItem extends React.Component<ArticleProps> {
    render() {
        return (
            <Link to={"/blog/" + this.props.data['id'] + "-" + this.props.data['slug']} className={"iblk qf bb ma-b-20 m-ma-b-20 pa-h-10"}>
                <div className={"fw"} style={{
                    height: 200,
                    backgroundImage: "url(https://cdn.strategicthinkingforprofit.com/img/article/thumbnail/" + this.props.data['thumbnail'] + ".jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}>
                </div>
                <div className={"b-bg wt pa-a-20 m-pa-a-20 lh3"}>
                    <div className={"b"} style={{minHeight: 48}}>{this.props.data['title']}</div>
                    <div>{this.props.data['excerpt']}</div>
                </div>
            </Link>
        )
    }
}