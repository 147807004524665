import {connect} from "react-dynadux";
import {API} from "../store/store";
import React from "react";

interface StoreProp {store: API;}

class HomeController extends React.Component<StoreProp> {
    render() {
        return (
            <div className={"ma-b-30"}>
                {/*
                <div className="b-bg pa-t-20 m-pa-t-30 m-pa-h-30 m-pa-b-10 ma-b-30">
                    <div className="wrapper ma-h-a df sbf">
                        <div className="hf pa-r-20 m-pa-b-20 m-ma-b-30">
                            {this.props.store.podcast.state.podcasts.slice(0, 1).map((item: any, i: number) => (
                                    <a key={i}
                                       href={"yes"}
                                       className={"df mf sf wt br"}
                                       style={{boxShadow: "0 0 10px rgba(0,0,0,.08)", height: 60}}>
                                        <div
                                            className={"bb bb-bg ma-r-20 m-ma-r-20 m-ma-b-20"}
                                            style={{
                                                flex: "0 0 100px",
                                                height: 100,
                                                backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/podcast/thumbnail/${item["thumbnail"]}.jpg")`,
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center"
                                            }}>
                                        </div>
                                        <div className="bb" style={{height: 100}}>
                                            <h5 className={"wt ma-b-5 lh4"}>{item.title}</h5>
                                            <span className={"iblk grt lh2"} style={{
                                                height: 80,
                                                whiteSpace: "break-spaces",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>{item.description}</span>
                                        </div>
                                    </a>
                                )
                            )}
                        </div>
                        <div className="hf df rw m-pa-t-20">
                            {this.props.store.podcast.state.podcasts.slice(1, 5).map((item: any, i: number) => (
                                    <div className="hf bb ma-b-20 m-ma-b-30" key={i}>
                                        <a
                                            href={"yes"}
                                            className={"df mf sf wt br pa-l-20"}
                                            style={{boxShadow: "0 0 10px rgba(0,0,0,.08)", height: 40}}>
                                            <div className={"br bb bb-bg ma-r-15 m-ma-r-15"}
                                                 style={{
                                                     flex: "0 0 40px",
                                                     height: 40,
                                                     backgroundImage: `url("https://cdn.strategicthinkingforprofit.com/img/podcast/thumbnail/${item["thumbnail"]}.jpg")`,
                                                     backgroundSize: "cover",
                                                     backgroundRepeat: "no-repeat",
                                                     backgroundPosition: "center"
                                                 }}>
                                            </div>
                                            <h6 className={"lh4"}>{item.title}</h6>
                                        </a>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>*/}
                <div className={"pa-t-20 m-pa-a-30 ma-h-a wrapper"}>
                    <h3 className={"pa-t-30 fw7 ma-b-10 m-ma-b-10 cursive3"}><span
                        className={"pt"}>STRATEGIC THINKING</span>
                    </h3>
                    <h5 className={"fw2 lh1 ma-b-20 m-ma-b-20 cursive3"} style={{textIndent: 50}}><em>The capacity
                        to prepare strategies and conjure ideas that will both cope with changing environments and
                        consider the various challenges that lie ahead.</em></h5>
                    <div className={"cursive4"} style={{fontSize: 15}}>
                        <p className={"tac ma-b-20 m-ma-b-20"}>Strategic thinking is not based on historical models.
                            It focuses on the future. It is creative and alive, ever moving and focused.</p>
                        <p className={"ma-b-20 m-ma-b-20"}>For the business owner or entrepreneur in today’s
                            competitive environment, the use of strategic thinking is imperative. The myriad of
                            choices in marketing and technology and the changes they encompass make being a business
                            owner complicated and less about what you do, but how you do it. A person that markets
                            the best wins… at first. We still must service our clients to the best of our abilities.
                            This means using our heads to maneuver the environment and at the same time keep up with
                            our own industries.</p>
                        <h4 className={"ma-b-20 m-ma-b-20 cursive3 fw7 tac"}><span className={"pt"}>"</span>Running
                            a highly profitable business can be learned.<span className="pt">"</span></h4>
                        <p className={"ma-b-20 m-ma-b-20"}>Too many small business owners are struggling with low
                            cash and no profit. Not understanding how they are so busy and at the same time not
                            getting to their goals. It is deflating, exhausting, and in many cases, unnecessary. The
                            problem is a lack of understanding about HOW to run a business. Yes, you heard that
                            right. Too many business owners don’t actually know how to run a business! You think,
                            well I’m good at what I do. Unfortunately, what you are good at is not running a
                            business. There is good news, however. Just like everything else, running a highly
                            profitable business can be learned. Don’t be hard on yourself, you are not alone. The
                            statistics for how many businesses fail in the first 2 to 5 years is extremely high.</p>
                        <p className={"ma-b-20 m-ma-b-20"}>At Strategic Thinking for Profit, the goal is to help to
                            equip you with the strategies and skills you need to become a success. No gurus here. We
                            are going to provide real-life answers to your most pressing questions and even
                            questions you didn’t know needed answering. Through newsletters, podcasts, courses and
                            other opportunities, we will bring to you what you need most to become a success.</p>
                    </div>
                    <h3 className={"pa-t-30 ma-b-10 m-ma-b-10 fw9"}><span className={"pt"}>Follow us</span> on this
                        journey. </h3>
                    <h6 className={"fw4 ma-b-10 m-ma-b-10 lh3"} style={{fontSize: 15}}>Connect with Strategic
                        Thinking for Profit on Facebook, Instagram, and Twitter.</h6>
                    <h6 className={"fw4 lh3"} style={{fontSize: 15}}>Contact us to learn more about how we can help
                        you <span className="ft">Level Up</span>. <span className={"ft"}>Make More</span>. <span
                            className="ft">Work less</span>.</h6>
                </div>
            </div>
        )
    };
}

export const Home = connect(
    HomeController,
    {
        shouldComponentUpdate: (action) => action.startsWith('P__')
    },
);