import React from "react";

export default class About extends React.Component {
    render() {
        return(
            <div className={"m-pa-h-30 ma-b-30 m-ma-b-30 ma-h-a"} style={{maxWidth: 860}}>
                <div className={"pa-t-15 pa-h-15 pa-b-10 m-pa-b-10"} style={{background: "rgba(100, 90, 140, 0.15)", marginBottom: 50}}>
                    <img src={"https://cdn.strategicthinkingforprofit.com/img/about/about0.jpg"} className={"blk m-fw ma-r-20 m-ma-b-20"} style={{maxWidth: 445, float: "left"}} alt={""} title={""} />
                    <h2 className={"cursive m-pa-h-20 ma-b-10 m-ma-b-10"}><span className={"pt"}>"</span>Everyone has a story.<span className={"pt"}>"</span></h2>
                    <p className={"m-pa-h-20"}>How did I evolve from a shy, single mother with no apparent entrepreneurial skills to a leader helping small businesses to raise their bottom line. Everyone has a story I believe and most people’s life is not a straight line from what you thought it would be to where you are today. My story is no different. A squiggly mess with no correlation to where I am today. How did I get here? – I often ask myself, proudly! Going from not having any clue to getting multiple degrees and certifications to become a successful leader in an accounting & advisory firm was a wild ride. Filled with pitfalls and hurdles to climb. Sorry no… Mountains!</p>
                </div>
                <h4 className={"cursive ma-b-20 m-ma-b-20 tac"}><span className={"pt"}>"</span>Exposure to the right info can <span className={"ft"}>change your world</span>.<span className={"pt"}>"</span></h4>
                <p className={"ma-b-20 m-ma-b-20"}>From early on, I was scared of math and business. I didn’t want to take calculus, statistics or anything even resembling what I believed to be out of my league, so I avoided them at all cost. As a single mother, I struggled to find what I wanted to be. I knew I didn’t want to throw mail at the post office for the rest of my life and started on my journey to find something else. I knew I could make decent money and retire from the post office, but there was always something searching for more in my subconscious. My supervisor asked me why I wanted to leave. He said I can make good money as a supervisor. I could not see that as my life goal. It’s great for others, but I knew that’s not what I am built for. I decided to try and sell financial services as a side hustle. I got license after license. And guess what… I suck at sales! I am definitely not built for selling other people’s stuff. But through that experience, I discovered finance and decided that would be my career goal. Finally!</p>
                <p className={"ma-b-20 m-ma-b-20"}>I absolutely loved statistics and calculus and financial equations, etc, etc. Couldn’t believe how much I enjoyed doing the work. I was at the top of my class. Through that exposure, I found that what I was afraid of actually came quite naturally for me.Who knew? Exposure to the things we fear often become a catalyst for success. Forcing one self to be more creates the change that puts us on the right path. I am in a constant state of learning and I now fear nothing.</p>
                <h4 className={"cursive ma-b-20 m-ma-b-20 tac"}><span className={"pt"}>"</span>A problem for you as a business owner is a lack of understanding about what is needed to <span className={"ft"}>grow</span> your business.<span className={"pt"}>"</span></h4>
                <img src={"https://cdn.strategicthinkingforprofit.com/img/about/about1.jpg"} className={"blk ma-r-20 m-ma-r-20"} style={{maxWidth: 250, float: "left"}} alt={""} title={""} />
                <p className={"ma-b-20 m-ma-b-20"}>I certainly didn’t have that understanding at first. Most entrepreneurs, including myself, go into their venture thinking that if they do a good job with their own skill then they will be successful. If you are the best chef, the best photographer, the best attorney, then you will make a lot of sales and grow your business. What they don’t consider is that strategic and financial mismanagement will doom their business to failure. It is no different for an accounting firm. What I do for clients has nothing to do with how I manage my business.</p>
                <p className={"ma-b-20 m-ma-b-20"}>I grew from <strong>nothing</strong>, no capital, to where I am today from figuring out a key thing. As a single mother I had to think strategically to get by. This is no different than what I need to do for my business. What I do in my everyday life is the same as what I need to do for my business. Think Strategically! The problem often is that we don’t consider what we’ve done as strategic because it comes naturally to us. Single mothers are great at this! We often believe it to be common sense. Well, I am here to tell you, what you believe to be common sense is often a foreign concept to others. Think about your life’s struggles and how you were able to get out from under. Take those lessons and apply them to business. You are a smart cookie! Use the skills organically developed and develop them some more.</p>
                <p className={"ma-b-20 m-ma-b-20"}>Learning is <strong>vital</strong> for success. Often we are overwhelmed by the amount that we have to do in our everyday lives. Taking care of our business, our family. And your response… Now you want me to add learning to the mix? Absolutely! The more you <strong>learn</strong>, the less you have to work. Thinking strategically means making more while working less. It is learning to maneuver, outsource, streamline and charge more because you are worth more. It is learning to delegate while putting yourself in the position to take more vacations.</p>
                <p className={"ma-b-20 m-ma-b-20"}>This is not the path of an employee or even an entrepreneur. This is the path of a successful business owner.</p>
                <h4 className={"cursive ma-b-30 m-ma-b-30 tac"}><span className={"pt"}>"</span>The skills you will develop will give you what you want most… <span className={"ft"}>freedom!</span><span className={"pt"}>"</span></h4>
                <img src={"https://cdn.strategicthinkingforprofit.com/img/about/about2.jpg"} className={"blk fw"} alt={""} title={""} />
            </div>
        )
    };
}