import {ICreateStoreAPI} from "dynadux";
import axios from "axios";

interface State {
    podcasts: any;
    podcast: any;
    playing: any;
}

enum Actions {
    GET_PODCASTS = "P__GET_PODCASTS",
    LOAD_PODCASTS = "P__LOAD_PODCASTS",
    GET_PODCAST = "P__GET_PODCAST",
    LOAD_PODCAST = "P__LOAD_PODCAST",
    PLAY_PODCAST = "P__PLAY_PODCAST",
}

interface Podcast {
    id: string;
    slug: string;
    title?: string;
    cover?: string;
}

interface Data {
    data: Array<object>;
}

export const podcast = (store: ICreateStoreAPI) => {
    const section = store.createSection<State>({
        section: "podcast",
        initialState: {podcasts: [], podcast: [], playing: []},
        reducers: {
            [Actions.GET_PODCASTS]: ({dispatch}) => {
                axios.get("https://api.strategicthinkingforprofit.com/podcasts")
                    .then(r => {
                        dispatch(Actions.LOAD_PODCASTS, {data: r.data});
                    });
            },
            [Actions.LOAD_PODCASTS]: ({payload}) => {
                const {data}: Data = payload;
                return {podcasts: data};
            },
            [Actions.GET_PODCAST]: ({payload, dispatch}) => {
                const {id, slug}: Podcast = payload;
                axios.get(`https://api.strategicthinkingforprofit.com/podcast/${id}-${slug}`)
                    .then(r => {
                        dispatch(Actions.LOAD_PODCAST, {data: r.data});
                    });
            },
            [Actions.LOAD_PODCAST]: ({payload}) => {
                const {data}: Data = payload;
                return {podcast: data};
            },
            [Actions.PLAY_PODCAST]: ({payload}) => {
                const {data}: Data = payload;
                return {playing: data};
            },
        }
    });

    return {
        get state(): State {
            return section.state;
        },
        actions: {
            getPodcasts: (): void => section.dispatch<void>(Actions.GET_PODCASTS),
            getPodcast: (id: string, slug: string): void => section.dispatch<Podcast>(Actions.GET_PODCAST, {id, slug}),
        }
    }
};