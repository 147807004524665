import React from "react";

export default class BusinessLoan extends React.Component {
    render() {
        return (
            <div className={"df"}>
                <div className={"hf pa-a-20 bb ma-r-20 b-bg br"}>
                    <form className={"blk"}>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Loan<span className={"rt ma-l-5"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter an amount..."} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Rate (%)<span className={"ma-l-5 rt"}>*</span></div>
                            <input type={"number"} className={"blk fw bb pa-v-10 pa-h-15 br"} placeholder={"Enter between 0 to 100"} />
                        </label>
                        <label className={"blk ma-b-20"}>
                            <div className={"wt b ma-b-10"}>Term<span className={"ma-l-5 rt"}>*</span></div>
                            <select className={"blk fw bb pa-v-10 pa-h-15 br"}>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                            </select>
                        </label>
                        <button className={"blk fw bb pa-v-10 b sapphire wt br"}>Calculate</button>
                    </form>
                </div>
                <div className={"hf pa-a-20 bb sapphire wt br"}>
                    <div className={"ma-b-20"}>
                        <h5 className={"ma-b-20 fw4"}>Monthly Payment</h5>
                        <h4>$0.00</h4>
                    </div>
                    <div>
                        <h5 className={"ma-b-20 fw4"}>Total Repayment</h5>
                        <h4>$0.00</h4>
                    </div>
                </div>
            </div>
        )
    }
}